html {
  box-sizing: border-box;
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

p,
ul,
ol {
  margin: 0;
}

ul {
  list-style: none;
  padding-left: 0;
}

small {
  font-size: 80%;
}

a {
  color: #0366d6;
  text-decoration: none;
}

code {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
textarea {
  border: 1px solid silver;
  border-radius: 0;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  outline: 0;
}

@media (max-width: 768px) {
  html {
    font-size: 12px;
  }
}
